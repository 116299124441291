var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "page-title-info" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("app.documentTitle")))]),
        _c("h2", { attrs: { "data-cy": "knowledgeBasePlaceHolder" } }, [
          _vm._v(_vm._s(_vm.$t("routes.knowledgeBase"))),
        ]),
      ]),
      _vm.formName !== "" && _vm.isMobile
        ? _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "returnText",
                  attrs: { id: "returnButton", plain: "" },
                  on: { click: _vm.returnToHelpTopicSelector },
                },
                [
                  _c("v-icon", [_vm._v("mdi-arrow-left-circle-outline")]),
                  _c("span", { staticClass: "d-inline-block returnText" }, [
                    _vm._v("Return to Topic Selection"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "transition",
        {
          attrs: { name: "slide-fade", mode: "in-out" },
          on: { "after-leave": _vm.afterLeave },
        },
        [
          _c("fusion-application-form", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showAppForm || !_vm.isMobile,
                expression: "showAppForm || !isMobile",
              },
            ],
            staticClass: "mt-1",
            attrs: {
              id: "fusionForm",
              "data-cy": "knowledgeBaseApplicationForm",
              name: _vm.formName,
              "storage-mode": "user-single",
              "storage-location": "off",
              "save-event": "onSubmit",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "help-wizard" },
        [
          _c("fusion-help-wizard-selector", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showHelpWizard || !_vm.isMobile,
                expression: "showHelpWizard || !isMobile",
              },
            ],
            attrs: {
              transition: "slide-x-reverse-transition",
              "data-cy": "knowledgeBaseHelpWizard",
              title: "Topics",
              items: this.topics,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
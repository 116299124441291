import VueGtag from 'vue-gtag'
import { utils } from '@fusion/saas-components'
import links from './links'

let instance

export const getInstance = () => instance

const useGAWrapper = (opt) => {
  if (instance) return instance

  if (!opt.id) {
    throw new Error('No Google Analytics Key')
  }

  Vue.use(VueGtag, { id: opt.id })

  instance = new Vue({
    data () {
      return {
        links
      }
    },
    methods: utils.GAMethods
  })

  return instance
}

export default {
  install (vue, options) {
    vue.prototype.$ga = useGAWrapper(options)
  }
}

import {
  accountExists,
  emailVerified,
  isAuthorized
} from '@fusion/auth'
import { mixins } from '@fusion/saas-components'

export default {
  data: () => {
    return {
      defaultForm: 'help-wizard-2',
      showAppForm: false,
      showHelpWizard: true
    }
  },
  methods: {
    returnToHelpTopicSelector () {
      this.showAppForm = false
    },
    afterLeave () {
      this.$store.state.helpWizardSelector.selectedItem = undefined
      this.showHelpWizard = true
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    // additional checks added while component data is loaded into the state
    formName () {
      const name = this.$store.state.helpWizardSelector?.selectedItem ? this.$store.state.helpWizardSelector.selectedItem.name : ''
      // Values for mobile, both components always shown in desktop view
      if (name) {
        this.showAppForm = true
        this.showHelpWizard = false
      }
      return name
    },
    topics () {
      return this.$store.state.applicationForm?.formData ? this.$store.state.applicationForm.formData : []
    }
  },
  name: 'KnowledgeBase',
  mixins: [isAuthorized, accountExists, mixins.reactivateAccount, emailVerified]
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    releaseVersion: process.env.VUE_APP_VERSION
  },
  mutations: {
    setIsLoading (state, status) {
      state.isLoading = status
    }
  },
  actions: {
    startLoading ({ commit }) {
      commit('setIsLoading', true)
    },
    stopLoading ({ commit }) {
      commit('setIsLoading', false)
    }
  }
})

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("fusion-reactivate-account", {
        attrs: { open: _vm.dialogs.reactivateAccount },
        on: { close: this.closeReactivateAccount },
      }),
      _c("div", { staticClass: "page-title-info" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("app.documentTitle")))]),
        _c("h2", { attrs: { "data-cy": "privateHomePlaceHolder" } }, [
          _vm._v(_vm._s(_vm.$t("routes.privateHomePage"))),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
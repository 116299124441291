export default {
  data: () => {
    return {
      drawerOpen: false,
      drawerIsMini: true,
      logoSrc: `${process.env.VUE_APP_PUBLIC_PATH}img/tsi_link_logo.svg`,
      dialogs: {
        feedback: false
      }
    }
  },
  created () {
    document.title = this.$t('app.documentTitle')
  },
  computed: {
    router () {
      return this.$router?.options?.routes
    },
    user () {
      let user = {}
      if (this.$auth?.user?.email) {
        user = this.$auth.user
      }
      return user
    },
    drawerPermanent () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    drawerClipped () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    drawerMini () {
      return (
        (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.md) &&
        this.drawerIsMini
      )
    }
  },
  methods: {
    toggleDrawer () {
      if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg) {
        this.drawerIsMini = !this.drawerIsMini
      } else {
        this.drawerOpen = !this.drawerOpen
      }
    },
    navigateHome () {
      window.open('/')
    },
    async login () {
      await this.$auth.loginWithRedirect()
    },
    openFeedback () {
      this.dialogs.feedback = true
    }
  }
}

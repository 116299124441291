import Vue from 'vue'
import PublicHomePage from '../views/PublicHome'
import KnowledgeBase from '../views/KnowledgeBase'
import PrivateHomePage from '../views/PrivateHome'
import Help from '../views/Help'
import ReleaseNotes from '../views/ReleaseNotes'
import NonVerified from '../views/NonVerified/index.vue'

import axios from 'axios'
import { isOkResponse } from '@/services/utils'

Vue.component('Help', Help)
Vue.component('PublicHomePage', PublicHomePage)
Vue.component('PrivateHomePage', PrivateHomePage)
Vue.component('KnowledgeBase', KnowledgeBase)
Vue.component('ReleaseNotes', ReleaseNotes)
Vue.component('NonVerified', NonVerified)

const getComponentConstructor = (name) => {
  return Vue?.options?.components[name]
}

// Should only be used once in main.js during initialization
// Access the routes from this.$router?.options?.routes in Vue components
export const getRouter = async () => {
  const applicationId = process.env.VUE_APP_APPLICATION_ID

  const result = await axios.get(
    `${process.env.VUE_APP_APPLICATIONS_HOST}/applications/${applicationId}/navigation`
  )

  const routes = []

  if (isOkResponse(result.status)) {
    for (let i = 0; i < result.data.length; i++) {
      const route = result.data[i]
      route.component = getComponentConstructor(route.component)
      // add to routes only if component exists
      if (route.component || route.path === '*') {
        routes.push(route)
      }
    }
  } else {
    console.error('Failed to setup routes')
  }

  const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_PUBLIC_PATH,
    routes
  })

  return router
}

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Components, { Colors } from '@fusion/saas-components'
import '@fusion/saas-components/dist/fusion-saas-components.css'
import { i18n } from './i18n'
import VueGtag from 'vue-gtag'
import { getRouter } from './router/index'
import GAWrapper from './plugins/google-analytics'
import Auth0Plugin, { getAuthInstance } from '@fusion/auth'

export const vuetifyOptions = {
  customVariables: ['~/assets/vuetify-variables.scss'],
  theme: {
    themes: {
      light: {
        primary: Colors.mainPrimary,
        accent: Colors.mainQuaternary,
        secondary: Colors.dark100,
        info: Colors.mainPrimary,
        warning: Colors.accentPrimary,
        error: Colors.accentError,
        success: Colors.accentSuccess
      }
    }
  }
}

Vue.use(Vuetify)

const setup = async () => {
  const router = await getRouter()

  Vue.config.productionTip = false

  const permissionsOptions = {}

  const auth0Options = {
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      )
    },
    domain: process.env.VUE_APP_API_DOMAIN,
    clientID: process.env.VUE_APP_API_CLIENT,
    audience: process.env.VUE_APP_API_AUDIENCE,
    authCache: process.env.VUE_APP_AUTH_CACHE,
    accountsHost: null,
    permissionsOptions
  }

  if (window.Cypress && process.env.VUE_APP_AUTH_CACHE) {
    auth0Options.authCache = process.env.VUE_APP_AUTH_CACHE
  }

  Vue.use(Auth0Plugin, auth0Options)

  Vue.use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_GA_ID
      }
    },
    router
  )
  Vue.use(Components, {
    auth0Options,
    applicationID: process.env.VUE_APP_APPLICATION_ID,
    clientID: process.env.VUE_APP_CLIENT_ID,
    clientURL: process.env.VUE_APP_CLIENT_URL,
    accountsAPIHost: process.env.VUE_APP_ACCOUNTS_HOST,
    accountsAPIHostV2: process.env.VUE_APP_ACCOUNTS_HOST_V2,
    applicationsAPIHost: process.env.VUE_APP_APPLICATIONS_HOST,
    formsAPIHost: process.env.VUE_APP_FORMS_HOST,
    createAccountLink: process.env.VUE_APP_CREATE_ACCOUNT_URL,
    appPublicPath: process.env.VUE_APP_PUBLIC_PATH,
    auth: getAuthInstance
  })
  Vue.use(GAWrapper, { id: process.env.VUE_APP_GA_ID })

  new Vue({
    i18n,
    store,
    router,
    vuetify: new Vuetify(vuetifyOptions),
    render: h => h(App)
  }).$mount('#app')
}

setup()

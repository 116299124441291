import { locales } from './locales/utils'

/**
 * Dynamically loads all locale JSON files and sets the default.
 */
function loadLocaleMessages () {
  const appLocales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const componentLocales = require.context('@fusion/saas-components/src/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  appLocales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = Object.assign(componentLocales(key), appLocales(key))
    }
  })

  return messages
}

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: process.env.NODE_ENV !== 'development'
})
i18n.locales = locales

export { i18n }
